import React from 'react'
import HotelItem from './HotelItem'

const Hotel = ({
  isShowHotelTickets,
  tickets,
  search,
  setPreviewHotel,
  setModalHotelRooms,
}) => {
  if (!isShowHotelTickets) return
  if (!tickets?.data?.isBookHotel) return
  return (
    <>
      {tickets?.data?.data?.hotels?.properties?.map((hotel, index) => {
        console.log({ hotel })
        if (!search) {
          return (
            <HotelItem
              key={index}
              hotel={hotel}
              onChoose={() => {
                setPreviewHotel(hotel)
                setModalHotelRooms(true)
              }}
            />
          )
        }
        if (
          search &&
          hotel?.propertySummary?.name
            ?.toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return (
            <HotelItem
              key={index}
              hotel={hotel}
              onChoose={() => {
                setPreviewHotel(hotel)
                setModalHotelRooms(true)
              }}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default Hotel
