import React, { useEffect, useState } from 'react'
import ModalSummary from './components/ModalSummary'
import SelectedHotel from './components/SelectedHotel'
import ModalHotelRooms from './components/ModalHotelRooms'
import SelectedFlight from './components/SelectedFlight'
import ModalBaggage from './components/ModalBaggage'
import { useSearchParams } from 'react-router-dom'
import { baggageAndMealsApi, getTicketsData } from '../../api/requests/oos'
import { useMutation } from 'react-query'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Container,
  Flex,
  Skeleton,
  SlideFade,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'
import ModalMeals from './components/ModalMeals'
import ButtonBack from './components/ButtonBack'
import Searchbar from './components/Searchbar'
import Success from './components/Success'
import TripTitle from './components/TripTitle'
import OneWay from './components/OneWay'
import Hotel from './components/Hotel'
import DepartureFlight from './components/DepartureFlight'
import ReturnFlight from './components/ReturnFlight'
import {
  extractAddOnOptions,
  extractAddOnOptionsSmartComboDeparture,
  extractAddOnOptionsSmartComboReturn,
} from '../../utils/extractAddOnOptions'

function Home() {
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)')
  const [searchParams] = useSearchParams()

  const [search, setSearch] = useState('')

  const [selectedFlight, setSelectedFlight] = useState(null)
  const [selectedDepartureFlight, setSelectedDepartureFlight] = useState(null)
  const [selectedReturnFlight, setSelectedReturnFlight] = useState(null)
  const [selectedFlightBaggage, setSelectedFlightBaggage] = useState([])
  const [selectedDepartureFlightBaggage, setSelectedDepartureFlightBaggage] =
    useState([])
  const [selectedReturnFlightBaggage, setSelectedReturnFlightBaggage] =
    useState([])

  const [selectedFlightMeals, setSelectedFlightMeals] = useState([])
  const [selectedDepartureFlightMeals, setSelectedDepartureFlightMeals] =
    useState([])
  const [selectedReturnFlightMeals, setSelectedReturnFlightMeals] = useState([])

  const [modalBaggage, setModalBaggage] = useState(false)
  const [modalMeals, setModalMeals] = useState(false)
  const [modalHotelRooms, setModalHotelRooms] = useState(false)
  const [modalSummary, setModalSummary] = useState(false)

  const [previewHotel, setPreviewHotel] = useState(null)
  const [selectedHotel, setSelectedHotel] = useState(null)

  const [isSuccess, setIsSuccess] = useState(false)

  const tickets = useMutation(getTicketsData)
  const addOnsOneWay = useMutation(baggageAndMealsApi, {
    onSuccess: (resp) => {
      console.log({ resp, selectedFlight })

      const { baggageOptions, mealOptions } = extractAddOnOptions(resp)

      setSelectedFlightBaggage([baggageOptions[0]])
      setSelectedFlightMeals([mealOptions[0]])
      const tempSelectedFlight = {
        ...selectedFlight,
        journeys: [
          {
            ...selectedFlight?.journeys[0],
            segments: selectedFlight?.journeys[0]?.segments?.map((segment) => {
              return {
                ...segment,
                addOns: {
                  ...segment?.addOns,
                  baggageOptions: [
                    ...segment?.addOns?.baggageOptions,
                    ...baggageOptions,
                  ],
                  mealOptions: [
                    ...segment?.addOns?.mealOptions,
                    ...mealOptions,
                  ],
                },
              }
            }),
          },
        ],
      }
      const tempSelectedFlightBaggage =
        tempSelectedFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.baggageOptions?.length <= 0) return []
          return {
            ...segment?.addOns?.baggageOptions[0],
            route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
          }
        })
      const tempSelectedFlightMeals =
        tempSelectedFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.mealOptions?.length > 0)
            return {
              ...segment?.addOns?.mealOptions[0],
              route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
            }
        })
      setSelectedFlightBaggage(tempSelectedFlightBaggage)
      setSelectedFlightMeals(
        tempSelectedFlightMeals?.filter((x) => x !== undefined).length === 0
          ? []
          : tempSelectedFlightMeals
      )
      setSelectedFlight((prev) => {
        return {
          ...tempSelectedFlight,
        }
      })
      console.log('Baggage Options:', baggageOptions)
      console.log('Meal Options:', mealOptions)
      handleShowModalBaggage()
    },
  })
  const addOnsRoundTripDeparture = useMutation(baggageAndMealsApi, {
    onSuccess: (resp) => {
      console.log({ resp, selectedDepartureFlight })

      const { baggageOptions, mealOptions } = extractAddOnOptions(resp)

      const tempSelectedDepartureFlight = {
        ...selectedDepartureFlight,
        journeys: [
          {
            ...selectedDepartureFlight?.journeys[0],
            segments: selectedDepartureFlight?.journeys[0]?.segments?.map(
              (segment) => {
                return {
                  ...segment,
                  addOns: {
                    ...segment?.addOns,
                    baggageOptions: [
                      ...segment?.addOns?.baggageOptions,
                      ...baggageOptions,
                    ],
                    mealOptions: [
                      ...segment?.addOns?.mealOptions,
                      ...mealOptions,
                    ],
                  },
                }
              }
            ),
          },
        ],
      }
      const tempSelectedDepartureFlightBaggage =
        tempSelectedDepartureFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.baggageOptions?.length <= 0) return []
          return {
            ...segment?.addOns?.baggageOptions[0],
            route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
          }
        })
      const tempSelectedDepartureFlightMeals =
        tempSelectedDepartureFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.mealOptions?.length > 0)
            return {
              ...segment?.addOns?.mealOptions[0],
              route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
            }
        })
      setSelectedDepartureFlightBaggage(tempSelectedDepartureFlightBaggage)
      setSelectedDepartureFlightMeals(
        tempSelectedDepartureFlightMeals?.filter((x) => x !== undefined)
          .length === 0
          ? []
          : tempSelectedDepartureFlightMeals
      )
      setSelectedDepartureFlight(tempSelectedDepartureFlight)
    },
  })
  const addOnsRoundTripReturn = useMutation(baggageAndMealsApi, {
    onSuccess: (resp) => {
      console.log({ resp, selectedReturnFlight })

      const { baggageOptions, mealOptions } = extractAddOnOptions(resp)

      const tempSelectedReturnFlight = {
        ...selectedReturnFlight,
        journeys: [
          {
            ...selectedReturnFlight?.journeys[0],
            segments: selectedReturnFlight?.journeys[0]?.segments?.map(
              (segment) => {
                return {
                  ...segment,
                  addOns: {
                    ...segment?.addOns,
                    baggageOptions: [
                      ...segment?.addOns?.baggageOptions,
                      ...baggageOptions,
                    ],
                    mealOptions: [
                      ...segment?.addOns?.mealOptions,
                      ...mealOptions,
                    ],
                  },
                }
              }
            ),
          },
        ],
      }
      const tempSelectedReturnFlightBaggage =
        tempSelectedReturnFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.baggageOptions?.length <= 0) return []
          return {
            ...segment?.addOns?.baggageOptions[0],
            route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
          }
        })
      const tempSelectedReturnFlightMeals =
        tempSelectedReturnFlight?.journeys[0]?.segments?.map((segment) => {
          if (segment?.addOns?.mealOptions?.length > 0)
            return {
              ...segment?.addOns?.mealOptions[0],
              route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
            }
        })
      console.log({
        tempSelectedReturnFlight,
        tempSelectedReturnFlightBaggage,
        tempSelectedReturnFlightMeals,
      })
      setSelectedReturnFlightBaggage(tempSelectedReturnFlightBaggage)
      setSelectedReturnFlightMeals(
        tempSelectedReturnFlightMeals?.filter((x) => x !== undefined).length ===
          0
          ? []
          : tempSelectedReturnFlightMeals
      )
      setSelectedReturnFlight(tempSelectedReturnFlight)
      handleShowModalBaggage()
    },
  })
  const addOnsSmartComboReturn = useMutation(baggageAndMealsApi, {
    onSuccess: (resp) => {
      console.log({ resp, selectedReturnFlight })
      const { baggageOptions, mealOptions } =
        extractAddOnOptionsSmartComboDeparture(resp)
      //   let baggageOptions = []
      //   let mealOptions = []
      //   if (resp?.data?.journeys[0]?.addOnOptions?.baggageOptions?.length > 0) {
      //     baggageOptions = resp?.data?.journeys[0]?.addOnOptions?.baggageOptions
      //   }
      //   if (resp?.data?.journeys[0]?.addOnOptions?.mealOptions?.length > 0) {
      //     mealOptions = resp?.data?.journeys[0]?.addOnOptions?.mealOptions
      //   }
      const tempSelectedDepartureFlight = {
        ...selectedDepartureFlight,
        segments: selectedDepartureFlight?.segments?.map((segment) => {
          return {
            ...segment,
            addOns: {
              ...segment?.addOns,
              baggageOptions: [
                ...segment?.addOns?.baggageOptions,
                ...baggageOptions,
              ],
              mealOptions: [...segment?.addOns?.mealOptions, ...mealOptions],
            },
          }
        }),
      }
      const tempSelectedDepartureFlightBaggage =
        tempSelectedDepartureFlight?.segments?.map((segment) => {
          if (segment?.addOns?.baggageOptions?.length <= 0) return []
          return {
            ...segment?.addOns?.baggageOptions[0],
            route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
          }
        })
      const tempSelectedDepartureFlightMeals =
        tempSelectedDepartureFlight?.segments?.map((segment) => {
          if (segment?.addOns?.mealOptions?.length > 0)
            return {
              ...segment?.addOns?.mealOptions[0],
              route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
            }
        })
      setSelectedDepartureFlightBaggage(tempSelectedDepartureFlightBaggage)
      setSelectedDepartureFlightMeals(
        tempSelectedDepartureFlightMeals?.filter((x) => x !== undefined)
          .length === 0
          ? []
          : tempSelectedDepartureFlightMeals
      )
      setSelectedDepartureFlight(tempSelectedDepartureFlight)

      const { baggageOptionsReturn, mealOptionsReturn } =
        extractAddOnOptionsSmartComboReturn(resp)
      //   let baggageOptionsReturn = []
      //   let mealOptionsReturn = []
      //   if (resp?.data?.journeys[1]?.addOnOptions?.baggageOptions?.length > 0) {
      //     baggageOptionsReturn =
      //       resp?.data?.journeys[1]?.addOnOptions?.baggageOptions
      //   }
      //   if (resp?.data?.journeys[1]?.addOnOptions?.mealOptions?.length > 0) {
      //     mealOptionsReturn = resp?.data?.journeys[1]?.addOnOptions?.mealOptions
      //   }
      console.log({ baggageOptionsReturn, mealOptionsReturn })
      const tempSelectedReturnFlight = {
        ...selectedReturnFlight,
        segments: selectedReturnFlight?.segments?.map((segment) => {
          return {
            ...segment,
            addOns: {
              ...segment?.addOns,
              baggageOptions: [
                ...segment?.addOns?.baggageOptions,
                ...baggageOptionsReturn,
              ],
              mealOptions: [
                ...segment?.addOns?.mealOptions,
                ...mealOptionsReturn,
              ],
            },
          }
        }),
      }
      const tempSelectedReturnFlightBaggage =
        tempSelectedReturnFlight?.segments?.map((segment) => {
          if (segment?.addOns?.baggageOptions?.length <= 0) return []
          return {
            ...segment?.addOns?.baggageOptions[0],
            route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
          }
        })
      const tempSelectedReturnFlightMeals =
        tempSelectedReturnFlight?.segments?.map((segment) => {
          if (segment?.addOns?.mealOptions?.length > 0)
            return {
              ...segment?.addOns?.mealOptions[0],
              route: `${segment?.departureDetail?.airportCode}-${segment?.arrivalDetail?.airportCode}`,
            }
        })
      setSelectedReturnFlightBaggage(tempSelectedReturnFlightBaggage)
      setSelectedReturnFlightMeals(
        tempSelectedReturnFlightMeals?.filter((x) => x !== undefined).length ===
          0
          ? []
          : tempSelectedReturnFlightMeals
      )
      setSelectedReturnFlight(tempSelectedReturnFlight)
      handleShowModalBaggage()
    },
  })

  const isShowFlightTickets = () => {
    return (
      !tickets?.isLoading &&
      tickets?.data?.bookingFlightType == 'one_way' &&
      !selectedFlight
    )
  }

  const isShowDepartureFlightTickets = () => {
    return (
      !tickets?.isLoading &&
      (tickets?.data?.bookingFlightType == 'round_trip' ||
        tickets?.data?.bookingFlightType == 'smart_combo') &&
      !selectedDepartureFlight
    )
  }

  const isShowReturnFlightTickets = () => {
    return (
      !tickets?.isLoading &&
      (tickets?.data?.bookingFlightType == 'round_trip' ||
        tickets?.data?.bookingFlightType == 'smart_combo') &&
      selectedDepartureFlight &&
      !selectedReturnFlight
    )
  }

  const isShowHotelTickets = () => {
    // if (
    //   !tickets?.isLoading &&
    //   tickets?.data?.bookingFlightType == 'round_trip'
    // ) {
    //   return (
    //     !tickets?.isLoading &&
    //     tickets?.data?.isBookHotel &&
    //     selectedDepartureFlight &&
    //     selectedReturnFlight &&
    //     selectedDepartureFlightBaggage &&
    //     selectedReturnFlightBaggage &&
    //     !selectedHotel
    //   )
    // } else if (
    //   !tickets?.isLoading &&
    //   tickets?.data?.bookingFlightType == 'one_way'
    // ) {
    //   return (
    //     !tickets?.isLoading &&
    //     tickets?.data?.isBookHotel &&
    //     selectedFlight &&
    //     selectedFlightBaggage &&
    //     !selectedHotel
    //   )
    // } else if (
    //   !tickets?.isLoading &&
    //   !tickets?.data?.bookingFlightType == 'round_trip' &&
    //   !tickets?.data?.bookingFlightType == 'one_way' &&
    //   tickets?.data?.isBookHotel &&
    //   !selectedHotel
    // ) {
    //   return true
    // } else {
    //   return false
    // }
    if (
      tickets.isLoading === false &&
      tickets.data &&
      tickets.data.isBookHotel &&
      !selectedHotel
    ) {
      return true
    } else {
      return false
    }
  }

  const isShowSlideSelectBaggage = () => {
    return (
      (!tickets?.isLoading &&
        (tickets?.data?.bookingFlightType == 'round_trip' ||
          tickets?.data?.bookingFlightType == 'smart_combo') &&
        selectedDepartureFlight &&
        selectedReturnFlight &&
        selectedDepartureFlightBaggage?.length <= 0 &&
        selectedReturnFlightBaggage?.length <= 0) ||
      (!tickets?.isLoading &&
        tickets?.data?.bookingFlightType == 'one_way' &&
        selectedFlight &&
        selectedFlightBaggage?.length <= 0)
    )
  }

  const isShowSlideSummary = () => {
    const isSelectedFlight = selectedFlight === null ? false : true
    const isSelectedDepartureFlight =
      selectedDepartureFlight === null ? false : true
    const isSelectedReturnFlight = selectedReturnFlight === null ? false : true
    const isSelectedFlightBaggage =
      selectedFlightBaggage?.length === 0 ? false : true
    const isSelectedDepartureFlightBaggage =
      selectedDepartureFlightBaggage?.length === 0 ? false : true
    const isSelectedReturnFlightBaggage =
      selectedReturnFlightBaggage?.length === 0 ? false : true
    const isSelectedHotel = selectedHotel === null ? false : true

    const isOneWay = tickets?.data?.bookingFlightType == 'one_way'
    const isRoundTrip =
      tickets?.data?.bookingFlightType == 'round_trip' ||
      tickets?.data?.bookingFlightType == 'smart_combo'
    const isHotel = tickets?.data?.isBookHotel

    const flightAndBaggage = isSelectedFlight && isSelectedFlightBaggage
    const departureFlightAndBaggage =
      isSelectedDepartureFlight && isSelectedDepartureFlightBaggage
    const returnFlightAndBaggage =
      isSelectedReturnFlight && isSelectedReturnFlightBaggage

    const isCompletedDeparture = departureFlightAndBaggage === isRoundTrip
    const isCompletedReturn = returnFlightAndBaggage === isRoundTrip

    const isCompletedOneWay = flightAndBaggage === isOneWay
    const isCompletedRoundTrip = isCompletedDeparture && isCompletedReturn
    const isCompletedHotel = isSelectedHotel === isHotel
    return isCompletedOneWay && isCompletedRoundTrip && isCompletedHotel
  }

  function handleShowModalBaggage() {
    setModalBaggage(true)
  }

  const handleShowModalMeals = () => {
    setModalMeals(true)
  }

  useEffect(() => {
    tickets.mutate(searchParams.get('id'))
  }, [])

  useEffect(() => {
    console.log('Flight', selectedFlight)
    console.log('Baggage', selectedFlightBaggage)
    console.log('Meals', selectedDepartureFlightMeals)
  }, [selectedFlight, selectedFlightBaggage, selectedDepartureFlightMeals])

  useEffect(() => {
    console.log('Departure Flight', selectedDepartureFlight)
    console.log('Departure Baggage', selectedDepartureFlightBaggage)
    console.log('Departure Meals', selectedDepartureFlightMeals)
  }, [
    selectedDepartureFlight,
    selectedDepartureFlightBaggage,
    selectedDepartureFlightMeals,
  ])

  useEffect(() => {
    console.log('Return Flight', selectedReturnFlight)
    console.log('Return Baggage', selectedReturnFlightBaggage)
    console.log('Return Meals', selectedReturnFlightMeals)
  }, [
    selectedReturnFlight,
    selectedReturnFlightBaggage,
    selectedReturnFlightMeals,
  ])

  console.log({ tickets })

  return (
    <Container
      maxWidth="container.xl"
      as={Flex}
      gap="20px"
      flexDirection="column"
      paddingY="20px"
    >
      <ButtonBack />

      <TripTitle isSuccess={isSuccess} tickets={tickets} />

      <Searchbar value={search} onChange={(value) => setSearch(value)} />

      {tickets?.isError
        ? // ?   <Center>
          //         <Stack direction='row' alignItems='center'>
          //             <Icon as={AiOutlineInfoCircle} />
          //             <Text>{tickets?.error}</Text>
          //         </Stack>
          //     </Center>
          !isSuccess && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{tickets?.error}</AlertTitle>
            </Alert>
          )
        : !isSuccess && (
            <>
              <Flex gap={'10px'} direction={isLargerThan500 ? 'row' : 'column'}>
                {selectedFlight ? (
                  <SelectedFlight
                    type="departure"
                    data={selectedFlight}
                    onDelete={() => {
                      setSearch('')
                      setSelectedFlight(null)
                      setSelectedFlightBaggage([])
                    }}
                  />
                ) : null}
                {selectedDepartureFlight ? (
                  <SelectedFlight
                    type="departure"
                    data={selectedDepartureFlight}
                    onDelete={() => {
                      setSearch('')
                      setSelectedDepartureFlight(null)
                      setSelectedDepartureFlightBaggage([])
                    }}
                  />
                ) : null}
                {selectedReturnFlight ? (
                  <SelectedFlight
                    type="return"
                    data={selectedReturnFlight}
                    onDelete={() => {
                      setSearch('')
                      setSelectedReturnFlight(null)
                      setSelectedReturnFlightBaggage([])
                    }}
                  />
                ) : null}
                {selectedHotel ? (
                  <SelectedHotel
                    hotel={selectedHotel}
                    onDelete={() => setSelectedHotel(null)}
                  />
                ) : null}
              </Flex>

              {tickets?.isLoading ? (
                <VStack>
                  <Skeleton height="212px" width="100%" borderRadius="6px" />
                </VStack>
              ) : null}

              <OneWay
                isShowFlightTickets={isShowFlightTickets()}
                search={search}
                setSearch={setSearch}
                setSelectedFlight={setSelectedFlight}
                tickets={tickets}
              />

              <DepartureFlight
                isShowDepartureFlightTickets={isShowDepartureFlightTickets()}
                search={search}
                setSearch={setSearch}
                setSelectedDepartureFlight={setSelectedDepartureFlight}
                tickets={tickets}
              />

              <ReturnFlight
                isShowReturnFlightTickets={isShowReturnFlightTickets()}
                search={search}
                selectedDepartureFlight={selectedDepartureFlight}
                setSearch={setSearch}
                setSelectedReturnFlight={setSelectedReturnFlight}
                tickets={tickets}
              />

              <Hotel
                isShowHotelTickets={isShowHotelTickets()}
                search={search}
                setModalHotelRooms={setModalHotelRooms}
                setPreviewHotel={setPreviewHotel}
                tickets={tickets}
              />
            </>
          )}

      <Success isSuccess={isSuccess} />

      {isShowSlideSelectBaggage() && !isSuccess && (
        <SlideFade
          in={isShowSlideSelectBaggage()}
          // direction='bottom'
          // style={{ zIndex: 10 }}
        >
          <Flex
            w="100%"
            h="70px"
            boxShadow="0px 0px 10px 1px #e8e8e8"
            backgroundColor="white"
            alignItems="center"
          >
            <Container maxWidth="container.xl" as={Flex}>
              <Button
                backgroundColor="blue.500"
                color="white"
                marginLeft="auto"
                isLoading={
                  addOnsOneWay?.isLoading ||
                  addOnsRoundTripDeparture?.isLoading ||
                  addOnsRoundTripReturn?.isLoading ||
                  addOnsSmartComboReturn?.isLoading
                }
                onClick={async () => {
                  if (tickets?.data?.bookingFlightType === 'one_way') {
                    addOnsOneWay?.mutate({
                      flightId: [selectedFlight?.flightId],
                      bookingFlightType: tickets?.data?.bookingFlightType,
                    })
                  } else if (
                    tickets?.data?.bookingFlightType === 'round_trip'
                  ) {
                    await addOnsRoundTripDeparture?.mutateAsync({
                      flightId: [
                        selectedDepartureFlight?.flightId,
                        selectedReturnFlight?.flightId,
                      ],
                      bookingFlightType: tickets?.data?.bookingFlightType,
                    })
                    await addOnsRoundTripReturn?.mutateAsync({
                      flightId: [
                        selectedDepartureFlight?.flightId,
                        selectedReturnFlight?.flightId,
                      ],
                      bookingFlightType: tickets?.data?.bookingFlightType,
                    })
                  } else if (
                    tickets?.data?.bookingFlightType === 'smart_combo'
                  ) {
                    await addOnsSmartComboReturn?.mutateAsync({
                      flightId: [
                        //   selectedDepartureFlight?.flightId,
                        selectedReturnFlight?.flightId,
                      ],
                      //   flightId: selectedReturnFlight?.flightId,
                      bookingFlightType: tickets?.data?.bookingFlightType,
                    })
                  }
                }}
              >
                Select Baggage
              </Button>
            </Container>
          </Flex>
        </SlideFade>
      )}

      {isShowSlideSummary() && !isSuccess && (
        <SlideFade
          in={isShowSlideSummary()}
          // direction='bottom'
          // style={{ zIndex: 10 }}
        >
          <Flex
            w="100%"
            h="70px"
            boxShadow="0px 0px 10px 1px #e8e8e8"
            backgroundColor="white"
            alignItems="center"
          >
            <Container maxWidth="container.xl" as={Flex}>
              <Button
                backgroundColor="blue.500"
                color="white"
                marginLeft="auto"
                onClick={() => setModalSummary(true)}
              >
                Continue
              </Button>
            </Container>
          </Flex>
        </SlideFade>
      )}

      {!isSuccess && (
        <ModalBaggage
          type={tickets?.data?.bookingFlightType}
          isOpen={modalBaggage}
          onClose={() => setModalBaggage(false)}
          onContinue={() => {
            setModalBaggage(false)
            handleShowModalMeals()
          }}
          tickets={tickets}
          selectedFlight={selectedFlight}
          selectedFlightBaggage={selectedFlightBaggage}
          setSelectedFlightBaggage={setSelectedFlightBaggage}
          selectedDepartureFlight={selectedDepartureFlight}
          selectedDepartureFlightBaggage={selectedDepartureFlightBaggage}
          setSelectedDepartureFlightBaggage={setSelectedDepartureFlightBaggage}
          selectedReturnFlight={selectedReturnFlight}
          selectedReturnFlightBaggage={selectedReturnFlightBaggage}
          setSelectedReturnFlightBaggage={setSelectedReturnFlightBaggage}
        />
      )}

      {!isSuccess && (
        <ModalMeals
          type={tickets?.data?.bookingFlightType}
          isOpen={modalMeals}
          onClose={() => setModalMeals(false)}
          onContinue={() => {
            setModalMeals(false)
            setModalSummary(true)
          }}
          tickets={tickets}
          selectedFlight={selectedFlight}
          selectedFlightMeals={selectedFlightMeals}
          setSelectedFlightMeals={setSelectedFlightMeals}
          selectedDepartureFlight={selectedDepartureFlight}
          selectedDepartureFlightMeals={selectedDepartureFlightMeals}
          setSelectedDepartureFlightMeals={setSelectedDepartureFlightMeals}
          selectedReturnFlight={selectedReturnFlight}
          selectedReturnFlightMeals={selectedReturnFlightMeals}
          setSelectedReturnFlightMeals={setSelectedReturnFlightMeals}
          showModalBaggage={() => setModalBaggage(true)}
        />
      )}

      {modalHotelRooms && !isSuccess && (
        <ModalHotelRooms
          isOpen={modalHotelRooms}
          onClose={() => setModalHotelRooms(false)}
          previewHotel={previewHotel}
          trip={tickets?.data?.data?.trip}
          setSelectedHotel={setSelectedHotel}
        />
      )}

      {modalSummary && !isSuccess && (
        <ModalSummary
          selectedFlight={selectedFlight}
          selectedDepartureFlight={selectedDepartureFlight}
          selectedReturnFlight={selectedReturnFlight}
          selectedFlightBaggage={selectedFlightBaggage}
          selectedDepartureFlightBaggage={selectedDepartureFlightBaggage}
          selectedReturnFlightBaggage={selectedReturnFlightBaggage}
          selectedHotel={selectedHotel}
          trip={tickets?.data?.data?.trip}
          tickets={tickets?.data}
          isOpen={modalSummary}
          onClose={() => setModalSummary(false)}
          onSuccess={() => {
            setModalSummary()
            setIsSuccess(true)
          }}
          selectedMeals={selectedFlightMeals}
          selectedDepartureMeals={selectedDepartureFlightMeals}
          selectedReturnMeeals={selectedReturnFlightMeals}
          showModalMeals={() => setModalMeals(true)}
        />
      )}
    </Container>
  )
}

export default Home
